/**
 *
 * Custom function
 *
 */

(function($) {

    /*
     *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
     */

    function allClick() { 
        var clickbox = $('.allclick');
        clickbox.each(function() {
            var $this = $(this);
            $this.css('cursor', 'pointer');
            $this.find('a').click(function(event) {
                event.preventDefault();
            });

            if ($this.find('a').attr('target') == '_blank') {
                $this.click(function() {
                    window.open($('a', this).attr('href'));
                });
            } else {
                $this.click(function() {
                    window.location.href = $('a', this).attr('href');
                });
            }
        });
    }

    /*
     *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
     */

    function loadAsynchronousImage() {

        $('*[data-loadimg]').each(function() {
            var box = $(this),
                src = box.attr('data-loadimg'),
                alt = '',
                img = new Image();

            if (box.attr('data-alt')) {
                alt = box.attr('data-alt');
            }

            $(img).load(function() {
                box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
                $('img', box).hide().fadeIn();

            }).attr('src', src);
        });

        $('*[data-loadbackground]').each(function() {
            var box = $(this),
                src = box.attr('data-loadbackground');

            box.css({
                'background-image': 'url(' + src + ')',
                'background-repeat': 'no-repeat',
                'background-position': 'top center'
            });

            if (box.has(['data-position'])) {
                box.css({
                    'background-position': box.attr('data-position'),
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            } else {
                box.css({
                    'background-position': 'top center',
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            }

            if (box.attr('data-repeat') == 'repeat') {
                box.css({
                    'background-repeat': 'repeat'
                });
            } else {
                box.css({
                    'background-repeat': 'no-repeat'
                });
            }
        });
    }


    /*
     *	Funzione per la gestione dei bottoni "condividi" legati ai social
     */

    function socialOpen() {
        $('[data-socialurl]').each(function() {
            var $this = $(this),
                url = $this.attr('data-socialurl');

            $this.click(function() {
                window.open(url, '', 'width=500, height=500');
            });

        });
    }

    function trackAdwordsCampaign() {
        //$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
    }


    /*
     *	Funzione per l'apertura di una finestra modale tramite cookie
     */

    function openModal() {
        if($('#onloadmodal').length) {
            if (!$.cookie("openmodal")) {
                $('#onloadmodal').modal();
                $.cookie("openmodal", 'no', {
                    path: '/'
                });
            }
        }
    }



    /*
     *	Funzione per l'apertura di un iframe all'interno di una finestra modale
     */

    function iframeModalOpen() {
        $('.videoModal').each(function() {
            // impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
            $('.modalButton').on('click', function(e) {
                var src = $(this).attr('data-src');
                var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
                var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

                var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

                // stampiamo i nostri dati nell'iframe
                $(".videoModal iframe").attr({
                    'src': src,
                    'height': height,
                    'width': width,
                    'allowfullscreen': ''
                });
            });

            // se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
            $(this).on('hidden.bs.modal', function() {
                $(this).find('iframe').html("");
                $(this).find('iframe').attr("src", "");
            });
        });
    }

    /*
     *	Funzione per la formattazione delle tabelle con classe listino
     *	tramite le classi di bootstrap
     */

    function responsiveTable() {

        $('.entry-content table').each(function() {
            var $this = $(this);
            $this.addClass('table');
            $this.wrap('<div class="table-responsive"></div>');
        });

        if ($(window).width() <= 767) {

            $('#primary .table-responsive').prepend(
                '<span class="angle-scroll-left">' +
                '<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
                '</span>'
            );

            $('#primary .table-responsive').on('scroll', function(event) {
                var angleScrollLeft = $('.angle-scroll-left');

                if ($(this).scrollLeft() >= 1) {
                    angleScrollLeft.addClass('fade-out');
                } else {
                    angleScrollLeft.removeClass('fade-out');
                }
            });

            $('#primary').find('.table-responsive').each(function() {
                var table = $(this);
                $('.angle-scroll-left').click(function(event) {
                    table.animate({
                        scrollLeft: '+=60'
                    }, 200);
                });
            });
        }
    }

    /*
     *	Funzione per l'inizializzazione della mappa
     */
    function initializeGmap() {
        $('*[data-location]').each(function() {
            var map = $(this),
                address = map.attr('data-location');
            map.setGMap({
                address: address
            });
        });
    }

	
	function initializeMap() {

		var locations = new Array();
		var arrayMarker = new Array();
		var infoWindow = new google.maps.InfoWindow();

		var directionsService = new google.maps.DirectionsService();
		var directionsDisplay = new google.maps.DirectionsRenderer();


		//Instazio la mappa centrandola con le coordinate della prima localitò
		var map = new google.maps.Map(document.getElementById('map_canvas'), {
			center: new google.maps.LatLng($(".locations-list li:first-child a").data("location-lat"), $(".locations-list li:first-child a").data("location-lon")),
			zoom: 12,
			mapTypeId: google.maps.MapTypeId.ROADMAP
		});

		//Ciclo il menù con i punti di interesse per leggere gli attributi data dalla DOM
		$(".locations-list li").each(function(){

			var name = ($('a', this).data("location-name"));
			var img = ($('a', this).data("location-image"));
			var lat = ($('a', this).data("location-lat"));
			var lon = ($('a', this).data("location-lon"));
			var index = ($('a', this).data("location-index"));

			//Eventuale icona custom per il marker
			var iconMarket = 'http://cdn1.buuteeq.com/aspx/shared/images/location/Balloon/1/A.png';

			//Creazione contenuto infoWindow (title del post e immagine)
			var content = '<div class="info-window">'+
				'<div class="title">'+name+'</div>'+
				'<img src="'+img+'" alt="'+name+'">'
				'</div>';

			//Creazione del marker per un punto di interesse
			var marker = new google.maps.Marker({
				position: new google.maps.LatLng(lat,lon),
				map: map,
				icon: iconMarket,
				title: name
			});

			//Inserisco il marker appena creato in un vettore da usare successivamente
			arrayMarker[index] = marker;

			//Listener che al click sul marker apre l'infoWindow
			marker.addListener('click', function() {
				infoWindow.setContent(content);
				infoWindow.open(map, marker);
			});

			if($(this).find('a').hasClass('active')){
				
				//Apertura dell'infoWindow rispetto al punto di interesse attivo
				infoWindow.setContent(content);
				infoWindow.open(map, marker);

				//Recupero l'indice della località
				var index = $(this).find('a').data("location-index");
				
				if(index > 0){
					//Cambio il nome del punto di interesse nella DOM
					name =$(this).find('a').data("location-name");
					$("#location-title").html(name);

					//Visualizzo il contenuto testuale del punto di interesse nascondendo tutti gli altri (sono già stampati e resi invisibili)
					$("#info .item").addClass('hide');
					$("#info .item[data-index='"+index+"']").removeClass('hide');

					//Imposto la select per generare il percorso con la partenza dalla località attiva
					$("#directions select[name='from_direction']").prop("selectedIndex", index);
				}
			}

			//Evento al click dell'elenco puntato con i punti di interesse
			$('a', this).click(function(event) {

				//Setto la classe active sulla voce cliccata
				$(".locations-list li a").each(function(){
					$(this).removeClass('active');
				});
				$(this).addClass('active');

				//Mi leggo l'indice della località
				var index = $(this).data("location-index");

				//Mi carico il relativo marker 
				currentMarker = arrayMarker[index];

				//Centro la mappa sul marker
				map.setCenter(currentMarker.getPosition());

				//Apro infoWindow sul marker corrente
				infoWindow.setContent(content);
				infoWindow.open(map, currentMarker);

				//Cambio il nome del punto di interesse nella DOM
				name = $(this).data("location-name");
				$("#location-title").html(name)

				//Visualizzo il contenuto testuale del punto di interesse nascondendo tutti gli altri (sono già stampati e resi invisibili)
				$("#info .item").addClass('hide');
				$("#info .item[data-index='"+index+"']").removeClass('hide');

				//Imposto la select per generare il percorso con la partenza dalla località selezionata
				$("#directions select[name='from_direction']").prop("selectedIndex", index); 

				return false;
			});

		});


		//Gestione freccette di navigazione, controllo l'indice del menù delle località e eseguo il trigger del click sulla voce precedente o successiva
		$(".scrolling.prev").click(function(event) {
			var index = $(".locations-list li a.active").data("location-index");
			if(index == 0){
				index = $(".locations-list li").length-1;
			}else{
				index--;
			}
			$(".locations-list li a[data-location-index='"+index+"']").trigger("click");
		});

		$(".scrolling.next").click(function(event) {
			var index = $(".locations-list li a.active").data("location-index");
			if(index == ($(".locations-list li").length-1)){
				index = 0;
			}else{
				index++;
			}
			$(".locations-list li a[data-location-index='"+index+"']").trigger("click");
		});

		//Gestione del menù per selezionare il tipo di trasporto (Auto, Piedi, Bicicletta o Bus)
		$(".location-slideshow .mode_type").each(function(){
			$(this).click(function(event) {
				var index = $(this).data("index");
				$("#directions .mode_type").removeClass("selected");
				$(this).addClass("selected");
			});
		});

		//Form per stampare il percorso tra i due punti di interesse settati sulle select
		$(".location-slideshow .search-directions").click(function(event) {

			var travel = $(".location-slideshow .mode_type.selected").data("value")

			var from = $("#directions select[name='from_direction']").val().split(",");
			var to = $("#directions select[name='to_direction']").val().split(",");

			var request = {
				origin: new google.maps.LatLng(from[0], from[1]),
				destination: new google.maps.LatLng(to[0], to[1]),
				travelMode: google.maps.DirectionsTravelMode[travel]
			};

			directionsDisplay.setMap(map);
			directionsDisplay.setPanel(document.getElementById('map_route'));

			directionsService.route(request, function(response, status){
				
				if (status == google.maps.DirectionsStatus.OK){
					directionsDisplay.setDirections(response);
				}
				
			});

		});

	}
	
	
    /**
     * Funzione per l'attivazione del menu responsive
     */

    function responsiveMenu() {
        $('.main-navigation').find('a[href="#"]').each(function() {
            $(this).click(function(event) {
                event.preventDefault();
            });
        });

        // if ($(window).width() <= viewportSize) { // se minore o uguale a 1199px di default
            var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");
            var fixedMenu = $('#js-fixed-menu')[0];

            if (!fixedMenu) return;

            var stickyHeader = new Waypoint.Sticky({
                element: fixedMenu
            });

            $('.main-navigation').find('a[href="#"]').each(function() {
                $(this).click(function(event) {
                    menuPrimaryOffcanvas.addClass('open');
                    var self = $(this);

                    if(self.hasClass('open')) {
                        setTimeout(function() { 
                            menuPrimaryOffcanvas.removeClass('open');
                        }, 500);
                    }

                    setTimeout(function() { 
                        $('a[href="#"]').not(self).removeClass('open');
                        $('ul.sub-menu').not(self.next()).removeClass('open');
                        self.next().toggleClass('open');
                        self.toggleClass('open');
                    }, 250);
                });
            });

            $("#js-menu-offcanvas-button-open").click(function() {
                menuPrimaryOffcanvas.addClass("offcanvas-open");
            });

            $("#js-menu-offcanvas-button-close").click(function() {
                menuPrimaryOffcanvas.removeClass("offcanvas-open");
            });
        // } else {
        //     var stickyHeader = new Waypoint.Sticky({
        //         element: $('#js-header')[0],
        //         // offset: -250 // l'offset è utile se non vuoi far partire subito fisso il menu
        //     });
        // }
    }


    /*
     *  Funzioni per PhotoSwiper
     */
    function addImageSizeForPhotoSwiper() {
        var thumbs = $('img[data-big-size]');
        thumbs.each(function(index) {
            if ($(this).parent('a').length > 0) {
                var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
                if (isImageLink) {
                    big_size = $(this).data('big-size');
                    link = $(this).parent('a');
                    link.attr('data-size', big_size);
                    link.wrap('<div class="single-image"></div>');
                }
            }
        });
    }

    function initPhotoSwiper(selector) {
        var galleryItems = $(selector);
        var pswpItems = [];
        // galleryItems.each(function(index) {
        //     var dimensions = $(this).find('a').data('size').split('x');
        //     var width = parseInt(dimensions[0], 10);
        //     var height = parseInt(dimensions[1], 10);

        //     myImage = {
        //         src: $(this).find('a').attr('href'),
        //         w: width,
        //         h: height,
        //         msrc: $(this).find('img').attr('src')
        //     };
             
        //     pswpItems.push(myImage);
        // });


        // galleryItems.each(function(index) {
        //     $(this).click(function(evt) {
        //         evt.preventDefault();
        //         openPhotoSwipe(pswpItems, index);
        //     });
        // });

        var originalItems = galleryItems.closest('.original');

        originalItems.each(function(index) {
            var dimensions = $(this).find('a').data('size').split('x');
            var width = parseInt(dimensions[0], 10);
            var height = parseInt(dimensions[1], 10);

            console.log($(this));

            var myImage = {
            src: $(this).find('a').attr('href'),
            w: width,
            h: height,
            msrc: $(this).find('img').attr('src')
            };

            pswpItems.push(myImage);
        });

        originalItems.each(function(index) {
            $(this).click(function(evt) {
            evt.preventDefault();
            openPhotoSwipe(pswpItems, index);
            });
        }); 
    }

    function openPhotoSwipe(galleryItems, index) {
        var options = {
            index: index
        };
        var pswpElement = $('.pswp')[0];

        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
        gallery.init();
    }


    function smoothScroll() {
        $("a[href*='#']").not("[href='#'],[data-toggle]").click(function() {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {

                    if(target.attr('data-paddingTopScroll')) {
                        var adjust = target.attr('data-paddingTopScroll');
                    }
                    else {
                        var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
                    }
                    
                    $('html, body').animate({
                        scrollTop: target.offset().top - adjust
                    }, 1000);
                    
                    return false;
                }
            }
        });
    }

    function startOwl(selector) {
        $(selector).each(function(args) {
            var owl = $(this);

            const nonSlides = owl.find('.non-slide').detach();
            
            var params = {
                nav : owl.data('owl-navigation'),
                navSpeed : owl.data('owl-slide-speed'),
                autoplay : owl.data('owl-autoplay'),
                autoplayTimeout : owl.data('owl-autoplay-timeout'),
                dots : owl.data('owl-dots'),
                dotsSpeed : owl.data('owl-dots-speed'),
                loop : owl.data('owl-loop'),
                autoHeight : false,
                autoWidth : owl.data('owl-auto-width'),
                smartSpeed : owl.data('owl-smart-speed'),
                fluidSpeed : owl.data('owl-fluid-speed'),
                slideTransition : owl.data('owl-slide-transition'),
                stagePadding : owl.data('owl-stage-padding'),
                animateOut : owl.data('owl-animation-out'),
                animateIn : owl.data('owl-animation-in'),
                margin : owl.data('owl-margin'),
                lazyLoad : true,
                navText: [ 
                    '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 12" width="79" height="12"><style>.a{fill:none;stroke:#1d1d1b;stroke-linecap:round;stroke-miterlimit:100}.b{fill:none;stroke:#1d1d1b;stroke-miterlimit:100}</style><path class="a" d="m78 6.3h-74.1"/><path class="b" d="m5.9 11.7l-5.5-5.4"/><path class="b" d="m0.4 6.3l5.5-5.4"/></svg>',
                    '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 14" width="79" height="14"><style>.a{fill:none;stroke:#1d1d1b;stroke-linecap:round;stroke-miterlimit:100}.b{fill:none;stroke:#1d1d1b;stroke-miterlimit:100}</style><path class="a" d="m1 7.1h74"/><path class="b" d="m73 1.6l5.5 5.5"/><path class="b" d="m78.5 7.1l-5.5 5.5"/></svg>'
                ], 
                onInitialized: function(event) {
                    var $carousel = $(event.target);

                    // Seleziona gli elementi originali del carosello
                    var $originalItems = $carousel.find('.owl-item:not(.cloned)');

                    // Aggiungi la classe "original" agli elementi originali
                    $originalItems.addClass('original');
                }               
            };

            if(owl.data('owl-slide-link')) {

                var linkedCarousel = $(owl.data('owl-slide-link'));

                owl.on('changed.owl.carousel', function(event) {
                    var slides = event.relatedTarget;
                    var currentIndex = slides.relative(slides.current());
                    linkedCarousel.trigger('to.owl.carousel', [currentIndex, 1000]);
                });
            }

            if (owl.data('owl-counter')) {
                var totalSlides = owl.find('.item').length;
                var currentSlideElem = owl.siblings('.carousel-counter').find('.currentSlide');
                var totalSlidesElem = owl.siblings('.carousel-counter').find('.totalSlides');
                totalSlidesElem.text(totalSlides);

                owl.on('changed.owl.carousel', function(event) {
                    var currentSlide = event.item.index - event.relatedTarget._clones.length / 2 + 1;

                    if(currentSlide == 0) {
                        currentSlide = totalSlides;
                    }
                    currentSlideElem.text(currentSlide);
                });

            }

            if (owl.data('owl-prev-next-cities')) {

                params['navText'] = [
                    '<div class="owl-prev-content"><div id="prevCityContainer">N/A</div><div class="arrow"><svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 12" width="79" height="12"><style>.a{fill:none;stroke:#1d1d1b;stroke-linecap:round;stroke-miterlimit:100}.b{fill:none;stroke:#1d1d1b;stroke-miterlimit:100}</style><path class="a" d="m78 6.3h-74.1"/><path class="b" d="m5.9 11.7l-5.5-5.4"/><path class="b" d="m0.4 6.3l5.5-5.4"/></svg></div></div>', 
                    '<div class="owl-next-content"><div id="nextCityContainer">N/A</div><div class="arrow"><svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 14" width="79" height="14"><style>.a{fill:none;stroke:#1d1d1b;stroke-linecap:round;stroke-miterlimit:100}.b{fill:none;stroke:#1d1d1b;stroke-miterlimit:100}</style><path class="a" d="m1 7.1h74"/><path class="b" d="m73 1.6l5.5 5.5"/><path class="b" d="m78.5 7.1l-5.5 5.5"/></svg></div></div>'
                ];

                params.onTranslate = function(event) {
                    var currentIndex = event.item.index;
                    var totalItems = event.item.count;

                    // Aggiorna i div all'interno dei pulsanti di navigazione
                    owl.find('.owl-prev-content #prevCityContainer').fadeOut(200);
                    owl.find('.owl-next-content #nextCityContainer').fadeOut(200, function() {
                        // Città precedente e successiva aggiornate dopo fadeOut
                        var prevCity = $('.owl-item').eq((currentIndex - 1 + totalItems) % totalItems).find('.city').text() || 'N/A';
                        var nextCity = $('.owl-item').eq((currentIndex + 1) % totalItems).find('.city').text() || 'N/A';

                        owl.find('.owl-prev-content #prevCityContainer').text(prevCity).fadeIn(200);
                        owl.find('.owl-next-content #nextCityContainer').text(nextCity).fadeIn(200);
                    });
                };

            }

            if(owl.data('owl-video-gallery')) {
                params['onTranslate'] = function(event) {

                    var currentSlide, player, command;

                    currentSlide = $('.owl-item.active');

                    player = currentSlide.find(".item iframe").get(0);

                    command = {
                        "event": "command",
                        "func": "pauseVideo"
                    };

                    if (player != undefined) {
                        player.contentWindow.postMessage(JSON.stringify(command), "*");

                    }

                }
            }

            if (owl.data('owl-single-item')) {
                params['items'] = 1;
            } else {
                params['responsive'] = {
                    0: {
                        items: owl.data('owl-items-xs'),
                    },
                    768: {
                        items: owl.data('owl-items-sm'),
                    },
                    992: {
                        items: owl.data('owl-items-md'),
                    },
                    1200: {
                        items: owl.data('owl-items-lg'),
                    },
                    1440: {
                        items: owl.data('owl-items-xl'),
                    }
                };
            }

            if(owl.data('owl-custom-arrows')) {
                params['navText'] = [
                    '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="85px" height="31px" viewBox="0 0 85 31" enable-background="new 0 0 85 31" xml:space="preserve">  <image id="image0" width="85" height="31" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFUAAAAfCAQAAAA8P0nbAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfnAxEMCSpr80BLAAABA0lEQVRYw+3WO24CMRQF0DsM3/BdBGwAiSIJZYBIbCFFdsdPVENCkw1QsIIsAEoIfyJy01CgZxhNgzwP8TpfW89HtmQZCE3xjXG/+Yht4Ek10ffHhqYYY4+eFmyUPQ7u2Ktgu7qwH5qwn1qwrj5swrYjKLajCdvWhR3esVfBtjhk0uE3igCAJX7Fmi02Ijngx+g0x59I1tiJZI+V3B8zo9MCX87oPBYe9lGndBxmEBMrkkiJxEXO6JM3PpIPkNcVR1okDgpGpywuvaMVlPGO8BcfOeWrbUUQ6JMmaMO2Ihh0ogWq5kSVQJ85Zd224sagEy1QJSda1QSt2VbcEhTgmC9+8/+djp3oI92s1gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wMy0xN1QxMTowOTo0MiswMTowMPsIFaIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDMtMTdUMTE6MDk6NDIrMDE6MDCKVa0eAAAAAElFTkSuQmCC" /></svg>', 
                    '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="85px" height="31px" viewBox="0 0 85 31" enable-background="new 0 0 85 31" xml:space="preserve">  <image id="image0" width="85" height="31" x="0" y="0" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFUAAAAfCAQAAAA8P0nbAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfnAxEMCSpr80BLAAABA0lEQVRYw+3WO24CMRQF0DsM3/BdBGwAiSIJZYBIbCFFdsdPVENCkw1QsIIsAEoIfyJy01CgZxhNgzwP8TpfW89HtmQZCE3xjXG/+Yht4Ek10ffHhqYYY4+eFmyUPQ7u2Ktgu7qwH5qwn1qwrj5swrYjKLajCdvWhR3esVfBtjhk0uE3igCAJX7Fmi02Ijngx+g0x59I1tiJZI+V3B8zo9MCX87oPBYe9lGndBxmEBMrkkiJxEXO6JM3PpIPkNcVR1okDgpGpywuvaMVlPGO8BcfOeWrbUUQ6JMmaMO2Ihh0ogWq5kSVQJ85Zd224sagEy1QJSda1QSt2VbcEhTgmC9+8/+djp3oI92s1gAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMy0wMy0xN1QxMTowOTo0MiswMTowMPsIFaIAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjMtMDMtMTdUMTE6MDk6NDIrMDE6MDCKVa0eAAAAAElFTkSuQmCC" /></svg>'
                ];
            }

            owl.owlCarousel(params);

            if(owl.data('owl-prev')){
                $(owl.data('owl-prev')).click(function(){
                    owl.trigger('prev.owl.carousel');
                });
            }
            if(owl.data('owl-next')){
                $(owl.data('owl-next')).click(function(){
                    owl.trigger('next.owl.carousel');
                });
            }

            if (owl.data('owl-prev-next-cities')) {
                var totalItems = owl.find('.owl-item').length;
                var initialIndex = owl.find('.owl-item.active').index();

                var initialPrevCity = owl.find('.owl-item').eq((initialIndex - 1 + totalItems) % totalItems).find('.city').text() || 'N/A';
                var initialNextCity = owl.find('.owl-item').eq((initialIndex + 1) % totalItems).find('.city').text() || 'N/A';

                owl.find('.owl-prev-content #prevCityContainer').text(initialPrevCity);
                owl.find('.owl-next-content #nextCityContainer').text(initialNextCity);
            }
        });
    }

    /*****************************************************************************************/

    function toConsole(string) {
        if ((typeof window.console == "undefined")) {
            alert(string);
        } else console.log(string);
    }

    function $j(argument) {
        var result = jQuery(argument);
        if (result.length === 0) return null;
        else return result;
    }

    function translateCountdown( langCountdown ) {

        countdown.resetLabels();
        
        if ( langCountdown == 'it' ) {
            countdown.setLabels(
                ' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
                ' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
                ' e ',
                ' '
            );
        } else if ( langCountdown == 'de' ) {
            countdown.setLabels(
                ' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
                ' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
                ' und ',
                ' '
            );
        } else if ( langCountdown == 'fr' ) {
            countdown.setLabels(
                ' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
                ' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
                ' et ',
                ' '
            );
        }

    }

	function edtCountdown(){        

		jQuery( ".edt_countdown_class" ).find('.item').each( function(){ 
            
		    var dataInizio = "";
		    var questo = jQuery(this);
		    jQuery.post(
			    url.ajax_url, 
			    {
			        'action': 'edita_ajax_get_start_countdown_date',
			        'offerId':   questo.data('offerid'),			        
			    }, 
			    
			    function(response){
					setInterval(function() {
                        stampaCountdown( questo, response );
                    }, 3600);
			    }
			);
	    });
	}

	function stampaCountdown( item, response ){

        jQuery( item ).find( '.pageTimer' ).empty().append(
            moment().countdown(response, countdown.DAYS|countdown.HOURS).toString()
        );        
	}

	function closePhotoswipe() {
	
	    $('.pswp__button--close').click(function() {
	        if($('.pswp').hasClass('pswp--open')) {
	            $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
	        }
	    });
	
	    $(window).scroll(function() {
	        if($('.pswp').hasClass('pswp--open')) {
	            $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
	        }
	    });
	}

    /*
	 * Funzione per utilizzare MixItUp da select
	 */

	function filterElements() {
		
        var containers = [
            {
                selectFilter: '.select-filters',
                selector: '.mix-filter-support',
                activator: '',
                initialFilter: 'all',
                scope: 'global'
            },
            // {
            //     selectFilter: '.select-filters-vacanza',
            //     selector: '.vacanza-container',
            //     activator: '.first-filter-vacanza',
            //     initialFilter: '',
            //     scope: 'local'
            // },
            // {
            //     selectFilter: '.select-filters-offerte-esperienze',
            //     selector: '.offerte-esperienze-container',
            //     activator: '.first-filter-offerte-esperienze',
            //     initialFilter: '',
            //     scope: 'local'
            // },
        ];

        containers.forEach(function(container) {

            if ($(container.selector).length) {

                var initialFilter = container.initialFilter;

                if ($(container.activator).length) {
                    $(container.activator).each(function() {
                        var firstFilter = $(this).children(':first-child').attr('class').split(' ')[0];
                        initialFilter = '.' + firstFilter;
                    });
                }

                if (initialFilter === '' && $(container.selector).data('initial-filter')) {
                    initialFilter = '.' + $(container.selector).data('initial-filter');
                }

                var config = {
                    selectors : {
                        control : '[data-mixitup-control]'
                    },
                    load: {
                        filter: initialFilter
                    },
                    controls: {
                        scope: container.scope
                    }
                };

                var mixer = mixitup(container.selector, config);

                $(container.selectFilter).on('change', function() {
                    console.log('change!');
                    mixer.filter(this.value);
                });

            }

        });

        $('.item-filter button').each(function() {
            $(this).on('click', function() {
                if (!$(this).hasClass('active')) {
                    $(this).parent().find('.filter.active').removeClass('active');
                }
            });
        });

    }

    /**
     * Questo oggetto ci serve a verificare il dispositivo utilizzato dall'utente,
     * la i. nell'espressione regolare sta ad indicare una ricerca case-insensitive,
     * il metodo nativo test() serve a verificare la presenza della parola nell'espressione regolare,
     * ritorna un boleano.
     */
    // var mapsMobileUrl = {
    //     isAndroid: function() {
    //         return /(android)/i.test(navigator.userAgent);
    //     },
    //     isIos: function() {
    //         return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
    //     }
    // };


    // function injectorLinkMaps()
    // {
    //     var buttonMaps = $('#js-fixed-menu').find('.navigator');

    //     if ( mapsMobileUrl.isAndroid() ) buttonMaps.attr( 'href', phpMapsMobileUrl.android );

    //     if ( mapsMobileUrl.isIos() ) buttonMaps.attr( 'href', phpMapsMobileUrl.ios );

    //     if ( ! mapsMobileUrl.isAndroid() && ! mapsMobileUrl.isIos() ) buttonMaps.hide();
    // }


    function slideMobile() {
        if (!$('body').hasClass('is-mobile')) return;

        var itemcount = 1;

		if( typeof mobileSize !=='undefined' ) {

	        $.each(mobileSize, function (index, item) {
	
	            if ($(window).width() > 767) {
	                $('.header-slideshow .itemcounter-' + itemcount + ' ' + 'img').attr({
	                    src: item[0]['tablet'][0],
	                    width: item[0]['tablet'][1],
	                    height: item[0]['tablet'][2],
	                });
	            } else {
	                $('.header-slideshow .itemcounter-' + itemcount + ' ' + 'img').attr({
	                    src: item[0]['mobile'][0],
	                    width: item[0]['mobile'][1],
	                    height: item[0]['mobile'][2],
	                });
	            }
	
	            itemcount++;
	        });
	     }
    }
	
	
	function getUrlVars() {
	    
	    var vars = [], hash;
	    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
	    for(var i = 0; i < hashes.length; i++)
	    {
	        hash = hashes[i].split('=');
	        vars.push(hash[0]);
	        vars[hash[0]] = hash[1];
	    }
	    return vars;
	}

    function accordion() {
        var accItem = document.getElementsByClassName("accordionItem");
        var accHD = document.getElementsByClassName("accordionItemHeading");

        if (accItem.length == 0) return;

        for (i = 0; i < accHD.length; i++) {
            accHD[i].addEventListener("click", toggleItem, false);
        }
        
        function toggleItem() {
            var itemClass = this.parentNode.className;
            for (i = 0; i < accItem.length; i++) {
                accItem[i].className = "accordionItem closeItem";
            }
            if (itemClass == "accordionItem closeItem") {
                this.parentNode.className = "accordionItem openItem";
            }

            var headerHeight = $('#js-header').outerHeight() + 50;

            $('html, body').animate({
                scrollTop: $(this).offset().top - headerHeight,
            }, 400);
        }
    }

    function getCookie(name) {

        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    function setCookie(name, value, days) {

        var d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    function addNumSlides(owl, rewind) {

        if (typeof rewind === "undefined") {
            rewind = true;
        }

        var prevNav = owl.find('.owl-prev');
        var nextNav = owl.find('.owl-next');
        var numSlides = $('<div>').addClass('numSlides');
        var autoplayTimeout = owl.attr('data-owl-autoplay-timeout');
        var loop = owl.attr('data-owl-loop');
        var isDragEvent;

        if(!autoplayTimeout || autoplayTimeout == "false") {
            autoplayTimeout = 6000;
        }

        numSlides.insertAfter(prevNav);
        numSlides.insertBefore(nextNav);

        updateNumSlides();

        if(loop == "true") {
            owl.on('drag.owl.carousel', function(event) {
                isDragEvent = true;
            });

            owl.on('changed.owl.carousel', function(event) {
                var slideValue;
                var currentSlideIndex = event.item.index;
                var allSlideElements = $(event.target).find('.owl-item');
                var currentSlideElement = allSlideElements.eq(currentSlideIndex);
                var otherSlideElements = allSlideElements.not(currentSlideElement);
                var clonedElements = currentSlideElement.prevAll('.owl-item.cloned');
                var currentIndex = event.item.index - clonedElements.length;

                if(currentSlideElement.hasClass('cloned')) {
                    slideValue = currentIndex;
                }
                else {
                    slideValue = currentIndex + 1;
                }
                
                if(slideValue == 0) {
                    slideValue = event.item.count;
                }

                updateNumSlides(slideValue);
            });
        }
        else {
            owl.on('changed.owl.carousel', function(event) {
                
                var slideValue = event.property.value + 1;

                if (event.item.index + 1 == event.item.count && rewind == true) {
                    // riparti dall'inizio del carousel
                    setTimeout(function(){
                        owl.trigger('to.owl.carousel', [0, 500, true]);
                    }, autoplayTimeout);
                }

                updateNumSlides(slideValue);
                
            });
        }

        function updateNumSlides(cur) {
            if (typeof cur === "undefined") {
                cur = 1;
            }

            if(loop == "true") {
                var totalSlides = owl.find('.owl-item:not(.cloned)').length;
            }
            else {
                var totalSlides = owl.find('.owl-item').length;
            }

            var currentSlide = cur;
            numSlides.html('<span class="current">' + currentSlide + '</span>' + '<span class="total">' + totalSlides + '</span>');
            
        }
    }


    function changeNewsBackground() {
        const $newsSection = $('.news');

        $('.news-slideshow').on('translated.owl.carousel', function(event) {
            const $activeItem = $(event.target).find('.owl-item.active .news-block');
            const newBg = $activeItem.data('bg');

            console.log(newBg)
            
            if (newBg) {
                $newsSection.css('background-image', `url(${newBg})`);
            }
        });

        // Imposta il primo sfondo all'avvio
        const initialBg = $('.news-slideshow .owl-item.active .news-block').data('bg');
        if (initialBg) {
            $newsSection.css('background-image', `url(${initialBg})`);
        }
    }

    function initMap() {
        // Inizializza la mappa
        const mappaCentro = { lat: 41.9028, lng: 12.4964 }; // Centro iniziale (esempio: Roma)

        let mapZoom = 6;

        if($(window).width() < 1600) {
            mapZoom = 5;
        }

        const map = new google.maps.Map(document.getElementById('map'), {
            zoom: mapZoom,
            center: mappaCentro,
            styles: customStyle
        });

        // Aggiungi i marker dalla variabile PHP
        markersData.forEach(markerData => {
            const marker = new google.maps.Marker({
                position: { lat: markerData.lat, lng: markerData.lng },
                map: map,
                title: markerData.title,
                icon: {
                    url: url.imgPath + 'pinMap.png',
                    scaledSize: new google.maps.Size(47, 56),
                    origin: new google.maps.Point(0, 0), // Punto di origine dell'immagine
                    anchor: new google.maps.Point(20, 56), // Punto di ancoraggio
                },
            });

           // Aggiungi un infowindow al click sul marker
            const infowindow = new google.maps.InfoWindow({
                content: `<div class="infowindow-content">
                            <h3>${markerData.title}</h3>
                            <p>${markerData.stars} - ${markerData.city} - ${markerData.nation}</p>
                            <a href="${markerData.url}" class="details" target="${markerData.target}">${markerData.urlText}</a>
                        </div>`,
            });

            marker.addListener('click', () => {
                infowindow.open(map, marker);
            });
        });
    }

    $j(document).ready(function() {

		// match heights
		
		$('.offer-inner a').matchHeight();
		$('.offer-description').matchHeight();
		$('.offer-price').matchHeight();
		$('.buon-motivo').matchHeight();
		$('.buon-motivo .box-title').matchHeight();
		// $('.page-blocks .title').matchHeight();
		$('.unit-block .unit-description').matchHeight();
		
        allClick();
        responsiveTable();
        addImageSizeForPhotoSwiper();
        iframeModalOpen();
        responsiveMenu();
        filterElements();
        // injectorLinkMaps();
        slideMobile();
        accordion();

        const animateCounters = (section) => {
            const counters = section.querySelectorAll(".value-container .value");
            counters.forEach(counter => {
                const updateCounter = () => {
                    const target = +counter.getAttribute("data-target");
                    const current = +counter.innerText;
                    const increment = Math.ceil(target / 100);

                    if (current < target) {
                    counter.innerText = current + increment;
                    setTimeout(updateCounter, 10);
                    } else {
                    counter.innerText = target;
                    }
                };
                updateCounter();
            });
        };

        function fadeInLinesFromContainer(containerId, wordsPerLine = 5, delay = 500) {
            const container = document.getElementById(containerId);
            const text = container.textContent.trim(); // Recupera il testo senza spazi superflui
            const words = text.split(' '); // Divide il testo in parole

            // Suddivide il testo in righe basate sul numero di parole
            const lines = [];
            for (let i = 0; i < words.length; i += wordsPerLine) {
                lines.push(words.slice(i, i + wordsPerLine).join(' '));
            }

            // Svuota il contenitore e aggiunge progressivamente le righe
            container.innerHTML = '';

            lines.forEach((line, index) => {
                const lineElement = document.createElement('div');
                lineElement.className = 'line';
                lineElement.textContent = line;
                container.appendChild(lineElement);

                setTimeout(() => {
                lineElement.classList.add('visible');
                }, delay * index);
            });
        }

        // Funzione per gestire comportamenti specifici per ogni sezione
        const handleSectionAnimation = (section) => {
            
            if (section.classList.contains("indicatori-operativita")) {
                animateCounters(section);
            }

            if (section.classList.contains("quote-container")) {

                if($(window).width() > 1220) {
                    fadeInLinesFromContainer('quote-lines', 4, 500);
                }   
            }  

            if (section.classList.contains("dove-siamo")) {
                initMap();
            }
        };

        // IntersectionObserver generico
        const observer = new IntersectionObserver((entries, obs) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("animate");
                    handleSectionAnimation(entry.target);
                    obs.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.1
        });

         // Aggiungi sezioni da osservare
        const sections = document.querySelectorAll("[data-animate-on-viewport]");
        sections.forEach(section => observer.observe(section));
        
        startOwl('.owl-carousel');
        changeNewsBackground();
        smoothScroll();

        // addNumSlides($('.mainslider .owl-carousel'));

		closePhotoswipe();

        if ( typeof lang && lang != 'en' ) {
            translateCountdown( lang );
		}

        edtCountdown();

        var $submitNewsletter = $('#item-efb-512');
        var $inputNewsletter = $('#item-efb-521');

        $submitNewsletter.insertAfter($inputNewsletter);

        if ($('#form-info').length) {
            $('#form-info #efb-newslettersubscribe').prop('checked', true);
        }

        $('#efb-546').on('input', function() {
            let value = $(this).val();
            value = value.replace(/[^\d]/g, '');

            if (value.length >= 3) {
                value = value.slice(0, 2) + '/' + value.slice(2);
            }
            if (value.length >= 6) {
                value = value.slice(0, 5) + '/' + value.slice(5);
            }
            $(this).val(value.slice(0, 10));
        });

        $('.gform_wrapper form').each(function() {

            $(this).checkEditaForm();
        });

		
        $('.checkNewsletter').each(function() {
            $(this).checkEditaForm();
        });
        
        $('#menu-toggle').click(function() {

			$(this).toggleClass('open');
	        $('body').toggleClass('menu-opened');
	        
	        if($(this).hasClass('open')) {
		        
		        $('#main-menu').fadeIn('fast');

                setTimeout(() => {
                        $('#main-menu').addClass('open');
                }, 500);
		        
	        } else {
		        
		        $('#main-menu').removeClass('open').fadeOut('fast');
	        }
        });
        
        
        //note(@duncanmid): feedback pager
		
		//if( !$('body').hasClass('is-mobile') ) {
		
			if( $('.offers-slideshow .owl-nav').length > 0 ) {
				
				$('.offers-slideshow .owl-nav').appendTo('#offers-nav');
			}
		//}
		
		
		// select camere / offerta in form
		
		if( $( '#richiesta-informazioni-23' ).length > 0 ) {
			
			var tipologia = getUrlVars()['tipo'];
			
			if( tipologia ) {
				
				var type_it = 'Camera';
				
				if( tipologia === 'offer' ) {
					
					type_it = 'Offerta';
				}
				
				var nome = getUrlVars()['nome'];
				
				$('#efb-483').val( type_it +  ' - ' + nome.split('+').join(' ') );
				
			}
			
			if( tipologia === 'room' ) {
				
				var room = getUrlVars()['valore'];
				
				$( '#efb-479' ).val( room ).focus();
			}
		}

        $('.services-link').each( function() {
            $(this).on('click', function() {
                var popup = $(this).closest('.camera-text').find('.services-popup');
                var container = $(this).closest('article');
                popup.addClass('open');
                container.addClass('open');

                if($('body').is('.is-mobile')) {
                    $('body').addClass('open-popup');
                }
            });
        });

        $('.close-popup').each( function() {
            $(this).on('click', function() {
                var popup = $(this).parent();
                var container = $(this).closest('article');
                popup.removeClass('open');
                container.removeClass('open');

                if($('body').is('.is-mobile')) {
                    $('body').removeClass('open-popup');
                }
            });
        });

        if($('body').hasClass('page-template-template-richiesta-preventivo')) {
            var urlParams = new URLSearchParams(window.location.search);

            if(urlParams.get('nome')) {
                var roomName = urlParams.get('nome');
                $('#efb-432').val(url.roomQuoteMessage + ' ' + roomName);
            }
        }
    });


    $j(window).load(function() {
        /*
         *	Attraverso il Google Loader carico il modulo mappa
         */
        // if ( $('body').hasClass('page-template-template-mappa') ) {
            
        //     $.getScript('https://www.google.com/jsapi', function() {            
        //         if(url.key) {
        //             var map_params = 'language=' + url.lingua + '&key=' + url.key;
        //         }
        //         else {
        //             var map_params = 'language=' + url.lingua;
        //         }
                
        //         google.load('maps', '3', {
        //             other_params: map_params + '&libraries=places',
        //             callback: function() {
        //                 initializeGmap();
        //             }
        //         });
        //     });
        // }

        var gallery = $('.gallery');

        if ( gallery.length ) {
            gallery.each(function( index ) {
                
                initPhotoSwiper( "#" + $( this ).attr('id') + " .gallery-item");
            });
        }

        initPhotoSwiper(".entry-content .single-image");

        $('section.indicatori-operativita .box-container .box').equalHeights();
        $('section.indicatori-operativita .box-container .box .content').equalHeights();

        if($(window).width() > 1024) {
            $('.section-form .form-group').equalHeights();
            $('.clienti-gallery .slide').equalHeights(); 
            $('.unit-inner').equalHeights();
            // $('.unit-inner .title').equalHeights();
            $('.textblocks-gallery .item').equalHeights();
        }
    });

    $j(window).scroll(function() {
        if ($(this).scrollTop() > 0) {
            $('#js-header').addClass('scroll');
            $('.main-menu-inner > img').hide();
            $('#site-logo').hide(100);
        } else {
            $('#js-header').removeClass('scroll');
            $('.main-menu-inner > img').show();
            $('#site-logo').show(100);
        }
    });

    $j(window).resize(function() {
        if($(window).width() > 1024) {
            $('section.indicatori-operativita .box-container .box').equalHeights();
            $('section.indicatori-operativita .box-container .box .content').equalHeights();
        }
    });

})(jQuery);
